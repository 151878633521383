<template lang="pug">
  .manual-mode-price(
    :id="`${rentalPeriod}-${date}`"
    v-on="$listeners"
  )
    .applying-price(
      :class="cellClassNames"
    )
      span(
        v-if="isDisplayPrice"
      ) {{ $n(displayingPrice) }}
    BTooltip(
      v-if="isShowTooltip"
      :target="`${rentalPeriod}-${date}`"
      triggers="hover"
      placement="top"
    )
      .d-flex.align-items-center
        .yellow-dot
        span {{ tooltipData.priceFrom }}
        span.m-r-3.m-l-3 ->
        span {{ tooltipData.priceTo }}
</template>

<script>
  // components
  import { BTooltip } from "bootstrap-vue"

  // misc
  import { isUndefined, keys, pickBy } from "lodash-es"

  export default {
    components: {
      BTooltip
    },

    props: {
      prevDate: [String, undefined],
      date: String,
      nextDate: [String, undefined],
      currentPrices: Object,
      changedPrices: Object,
      priceClassNames: Object,
      rentalPeriod: String
    },

    computed: {
      prevPrice({ changedPrices, currentPrices, prevDate }) {
        return changedPrices[prevDate] || currentPrices[prevDate]?.applied_value
      },

      currentPrice({ changedPrices, currentPrices, date }) {
        return changedPrices[date] || currentPrices[date]?.applied_value
      },

      nextPrice({ changedPrices, currentPrices, nextDate }) {
        return changedPrices[nextDate] || currentPrices[nextDate]?.applied_value
      },

      displayingPrice({ date, changedPrices, currentPrices }) {
        return changedPrices[date] || currentPrices[date]?.applied_value
      },

      cellClassNames({ priceClassNames, cellClassNameByPrice }) {
        const classNames = keys(pickBy(priceClassNames, value => value))

        return [...classNames, cellClassNameByPrice]
      },

      cellClassNameByPrice({ prevPrice, currentPrice, nextPrice }) {
        if (prevPrice === currentPrice && currentPrice === nextPrice) {
          return "both-sides"
        } else if (prevPrice === currentPrice) {
          return "left-side"
        } else if (currentPrice === nextPrice) {
          return "right-side"
        } else {
          return ""
        }
      },

      isShowTooltip({ tooltipData }) {
        return tooltipData.priceFrom !== tooltipData.priceTo
      },

      tooltipData({ date, changedPrices, currentPrices }) {
        // no need to display tooltip if price not changed
        if (isUndefined(changedPrices[date])) return ""

        const priceFrom = Number(currentPrices[date]?.applied_value || 0)
        const priceTo = Number(changedPrices[date] || 0)

        return { priceFrom, priceTo }
      },

      isDisplayPrice({ prevPrice, currentPrice }) {
        // hides price value from calendar if price value of previous day is the same
        return prevPrice !== currentPrice
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/price-calendar.sass"

  .manual-mode-price
    align-items: center
    cursor: default
    display: flex
    height: 40px
    justify-content: center
    user-select: none
    width: 100%

    .applying-price
      align-items: center
      background-color: $default-white
      border: 1px solid $default-gray
      border-radius: 5px
      cursor: pointer
      display: flex
      justify-content: center
      height: 70%
      margin: 0 5px
      user-select: none
      width: 100%

      &.changed-manually
        +changed-manually

      &.changed-auto
        +changed-auto

      &.invalid
        background-color: $default-red-light

      &.selected
        background-color: $default-purple
        color: $default-white

      &.left-side
        margin-left: 0
        border-left: 0
        border-radius: 0 5px 5px 0

      &.right-side
        margin-right: 0
        border-right: 0
        border-radius: 5px 0 0 5px

      &.both-sides
        border-left: 0
        border-radius: 0 0 0 0
        border-right: 0
        margin-left: 0
        margin-right: 0

  +tooltip($default-purple)
    .yellow-dot
      +yellow-dot
</style>
